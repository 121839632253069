<template>
    <v-card ref="container">
        <v-card-title class="text-h5" ref="title">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeReportDialog()"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Field Report
            {{ report.date ? ' / ' + report.date : '' }}
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider class="mx-5" />
        <v-card-text
            class="py-0 my-2"
            :style="{ height: height + 'px', overflowY: 'auto' }"
        >
            <v-row class="mt-2 mb-4">
                <v-col cols="12" sm="4" class="py-0">
                    <v-menu
                        v-if="type == 'daily'"
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="report.date"
                                label="Date *"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="pt-1 mt-4"
                                required
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="report.date"
                            scrollable
                            :max="new Date().toISOString().substr(0, 10)"
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-menu
                        v-if="type == 'weekly'"
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="report.date"
                                label="Date *"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="pt-1 mt-4"
                                required
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="report.date"
                            range
                            scrollable
                            :max="new Date().toISOString().substr(0, 10)"
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                        prepend-icon="mdi-account-hard-hat"
                        label="project *"
                        v-model="project.name"
                        readonly
                        class="mt-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                    <v-combobox
                        v-model="report.projectLocation"
                        :items="projectLocations"
                        item-text="name"
                        hide-details
                        prepend-icon="mdi-map-marker"
                        placeholder="Weather Location *"
                        class="mt-2"
                    >
                    </v-combobox>
                </v-col>
            </v-row>

            <p>Select the information you want to see in the report:</p>
            <v-row class="my-2">
                <v-col cols="12" sm="4">
                    <v-row class="ml-0">
                        <v-simple-checkbox
                            color="primary"
                            class="mx-0"
                            v-model="dataOptions.weather"
                        ></v-simple-checkbox>
                        <p class="my-0 py-0">Weather</p>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row class="ml-0">
                        <v-simple-checkbox
                            color="primary"
                            class="mx-0"
                            v-model="dataOptions.generalInfo"
                        ></v-simple-checkbox>
                        <p class="my-0 py-0">General Info</p>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row class="ml-0">
                        <v-simple-checkbox
                            color="primary"
                            class="mx-0"
                            v-model="dataOptions.workLog"
                        ></v-simple-checkbox>
                        <p class="my-0 py-0">Work Log</p>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="my-2">
                <v-col cols="12" sm="4">
                    <v-row class="ml-0">
                        <v-simple-checkbox
                            color="primary"
                            class="mx-0"
                            v-model="dataOptions.equipmentLog"
                        ></v-simple-checkbox>
                        <p class="my-0 py-0">Equipment Log</p>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row class="ml-0">
                        <v-simple-checkbox
                            color="primary"
                            class="mx-0"
                            v-model="dataOptions.materialDelivery"
                        ></v-simple-checkbox>
                        <p class="my-0 py-0">Material Delivery</p>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row class="ml-0">
                        <v-simple-checkbox
                            color="primary"
                            class="mx-0"
                            v-model="dataOptions.activityLog"
                        ></v-simple-checkbox>
                        <p class="my-0 py-0">Activity Log</p>
                    </v-row>
                </v-col>
            </v-row>
            <!-- GENERAL INFO SECTION -->
            <v-col v-if="dataOptions.generalInfo" cols="12" class="mt-4">
                <h2>GENERAL INFO</h2>
                <v-divider class="mt-2" />
                <v-row class="mt-4">
                    <v-col cols="12" lg="2" sm="4" class="d-flex align-center">
                        <v-row class="d-flex align-center ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0 mt-2"
                                v-model="report.scheduleDelays"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0 ml-2">Any Schedule Delays?</p>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="10"
                        sm="8"
                        class="my-0 py-0 d-flex align-center"
                        v-if="report.scheduleDelays"
                        ><v-text-field
                            v-model="report.scheduleDelaysText"
                            class="mt-0 py-0"
                            hide-details
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row class="mt-6">
                    <v-col cols="12" lg="2" sm="4" class="d-flex align-center">
                        <v-row class="d-flex align-center ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0 mt-2"
                                v-model="report.weatherImpact"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0 ml-2">Any Weather Impact?</p>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="10"
                        sm="8"
                        class="my-0 py-0 d-flex align-center"
                        v-if="report.weatherImpact"
                        ><v-text-field
                            v-model="report.weatherImpactText"
                            class="mt-0 py-0"
                            hide-details
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row class="mt-6">
                    <v-col cols="12" lg="2" sm="4" class="d-flex align-center">
                        <v-row class="d-flex align-center ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0 mt-2"
                                v-model="report.accidents"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0 ml-2">Any Accidents On Site?</p>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="10"
                        sm="8"
                        class="my-0 py-0 d-flex align-center"
                        v-if="report.accidents"
                        ><v-text-field
                            v-model="report.accidentsText"
                            class="mt-0 py-0"
                            hide-details
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row class="mt-6">
                    <v-col cols="12" lg="2" sm="4" class="d-flex align-center">
                        <v-row class="d-flex align-center ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0 mt-2"
                                v-model="report.injuries"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0 ml-2">Any Injuries Reported?</p>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="10"
                        sm="8"
                        class="my-0 py-0 d-flex align-center"
                        v-if="report.injuries"
                        ><v-text-field
                            v-model="report.injuriesText"
                            class="mt-0 py-0"
                            hide-details
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row class="mt-6">
                    <v-col cols="12" lg="2" sm="4" class="d-flex align-center">
                        <v-row class="d-flex align-center ml-0">
                            <v-icon>mdi-text</v-icon>
                            <p class="my-0 py-0 ml-4">General Notes</p>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="10"
                        sm="8"
                        class="my-0 py-0 d-flex align-center"
                        ><v-textarea
                            v-model="report.generalNotes"
                            class="mt-0 py-0"
                            hide-details
                            rows="3"
                        ></v-textarea
                    ></v-col>
                </v-row>
            </v-col>
            <!-- WORK LOG SECTION -->
            <v-col v-if="dataOptions.workLog" cols="12" class="mt-4">
                <v-row class="my-2 mx-0"
                    ><h2>WORK LOG</h2>
                    <v-spacer />
                    <v-btn-toggle mandatory borderless style="height: 36px;">
                        <v-btn
                            @change="setWLPerUser(true)"
                            style="font-size: 12px; height: 36px;"
                            class="px-2 py-1"
                        >
                            Per User
                        </v-btn>
                        <v-btn
                            @change="setWLPerUser(false)"
                            style="font-size: 12px; height: 36px;"
                            class="px-2 py-1"
                        >
                            Accumulated
                        </v-btn>
                    </v-btn-toggle></v-row
                >
                <v-divider />

                <v-row v-if="dataOptions.perUser" class="my-2">
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.id"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Id</p>
                        </v-row></v-col
                    ><v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.userName"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">User Name</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.role"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Role</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.hours"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Hours</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.notes"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Notes</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.location"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Location</p>
                        </v-row></v-col
                    ></v-row
                >
                <v-row v-else class="my-2"
                    ><v-col cols="6" sm="3"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.trade"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Trade</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" sm="3"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.usersQty"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Workers Qty</p>
                        </v-row></v-col
                    ><v-col cols="6" sm="3"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.accumulatedHours"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Hours</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" sm="3"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="dataOptions.accumulatedNotes"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Notes</p>
                        </v-row></v-col
                    >
                </v-row>

                <v-data-table
                    :headers="workLogHeaders"
                    :items="userReports"
                    class="elevation-0 mt-4"
                    :mobile-breakpoint="480"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <!--ITEMS-->
                    <template v-slot:[`item.id`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.notes`]="{ item }">
                        <v-text-field v-model="item.workLogNotes" />
                    </template>
                    <template v-slot:[`item.time`]="{ item }">
                        <p class="py-0 my-0">{{ item.time.toFixed(1) }}</p>
                    </template>
                    <template v-slot:[`item.accumulatedNotes`]="{ item }">
                        <v-text-field v-model="item.workLogNotes" />
                    </template>
                    <template v-slot:[`item.trade`]="{ item }">
                        <v-text-field v-model="item.trade" />
                    </template>
                </v-data-table>
            </v-col>
            <!-- EQUIPMENT LOG -->
            <v-col v-if="dataOptions.equipmentLog" cols="12" class="mt-4">
                <h2>EQUIPMENT LOG</h2>
                <v-divider class="mt-2" />
                <v-data-table
                    :headers="equipmentHeaders"
                    :items="report.equipmentEntries"
                    class="elevation-0 mt-4"
                    :mobile-breakpoint="480"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:[`item.actions`]="{ item, index }">
                        <div class="d-flex justify-center">
                            <v-btn
                                small
                                icon
                                @click="editEquipmentEntry(item, index)"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                icon
                                @click="removeEntry(index, 'equipment')"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <!-- NEW -->
                    <template v-slot:[`body.append`]>
                        <tr class="no-hover-row">
                            <td
                                :colspan="equipmentHeaders.length"
                                style="text-align: center;"
                            >
                                <v-btn
                                    color="primary"
                                    class="mx-auto my-2"
                                    @click="openEquipmentEntry()"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    New Entry
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <!-- MATERIAL DELIVERY -->
            <v-col v-if="dataOptions.materialDelivery" cols="12" class="mt-4">
                <h2>MATERIAL DELIVERY</h2>
                <v-divider class="mt-2" />
                <v-row class="my-2">
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="materialDeliveryOptions.description"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Description</p>
                        </v-row></v-col
                    ><v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="materialDeliveryOptions.quantity"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Quantity</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="materialDeliveryOptions.unit"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Unit</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="materialDeliveryOptions.unitCost"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Unit Cost</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="materialDeliveryOptions.totalCost"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Total Cost</p>
                        </v-row></v-col
                    >
                    <v-col cols="6" md="2" sm="4"
                        ><v-row class="ml-0">
                            <v-simple-checkbox
                                color="primary"
                                class="mx-0"
                                v-model="materialDeliveryOptions.notes"
                            ></v-simple-checkbox>
                            <p class="my-0 py-0">Notes</p>
                        </v-row></v-col
                    ></v-row
                >
                <v-data-table
                    :headers="filterMaterialHeaders"
                    :items="report.materialEntries"
                    class="elevation-0 mt-4"
                    :mobile-breakpoint="480"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:[`item.unitCost`]="{ item }">
                        <p class="py-0 my-0">
                            {{ formatCurrency(item.unitCost) }}
                        </p>
                    </template>
                    <template v-slot:[`item.totalCost`]="{ item }">
                        <p class="py-0 my-0">
                            {{ formatCurrency(item.unitCost * item.qty) }}
                        </p>
                    </template>
                    <template v-slot:[`item.actions`]="{ item, index }">
                        <div class="d-flex justify-center">
                            <v-btn
                                small
                                icon
                                @click="editMaterialEntry(item, index)"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                icon
                                @click="removeEntry(index, 'material')"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <!-- NEW -->
                    <template v-slot:[`body.append`]>
                        <tr class="no-hover-row">
                            <td
                                :colspan="materialHeaders.length"
                                style="text-align: center;"
                            >
                                <v-btn
                                    color="primary"
                                    class="mx-auto my-2"
                                    @click="openMaterialEntry()"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    New Entry
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <!-- ACTIVITY LOG -->
            <v-col v-if="dataOptions.activityLog" cols="12" class="mt-4">
                <h2>ACTIVITY LOG</h2>
                <v-divider class="mt-2" />
                <v-data-table
                    :headers="activityHeaders"
                    :items="report.activityEntries"
                    class="elevation-0 mt-4"
                    :mobile-breakpoint="480"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:[`item.percentage`]="{ item }">
                        <p class="py-0 my-0">{{ item.percentage }} %</p>
                    </template>
                    <template v-slot:[`item.actions`]="{ item, index }">
                        <div class="d-flex justify-center">
                            <v-btn
                                small
                                icon
                                @click="editActivityEntry(item, index)"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                icon
                                @click="removeEntry(index, 'activity')"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <!-- NEW -->
                    <template v-slot:[`body.append`]>
                        <tr class="no-hover-row">
                            <td
                                :colspan="materialHeaders.length"
                                style="text-align: center;"
                            >
                                <v-btn
                                    color="primary"
                                    class="mx-auto my-2"
                                    @click="openActivityEntry()"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    New Entry
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <!-- ATTACHMENTS -->
            <v-col cols="12" class="mt-4">
                <h2>ATTACHMENTS</h2>
                <v-divider class="mt-2" />
                <div class="d-flex align-center flex-wrap mt-4">
                    <div
                        v-for="(file, i) in report.attachments"
                        :key="i"
                        class="d-flex"
                    >
                        <v-card
                            class="d-flex ma-0 ml-2 pa-1"
                            @click="openFileAttached(file.name)"
                            :style="{ position: 'relative' }"
                        >
                            <v-img
                                class="ma-0 pa-0"
                                max-width="150px"
                                max-height="150px"
                                :src="
                                    file.pdfPreview ? file.pdfPreview : file.url
                                "
                            >
                            </v-img>
                            <div>
                                <v-btn
                                    small
                                    icon
                                    color="red"
                                    elevation="4"
                                    :style="{
                                        position: 'absolute',
                                        top: '8px',
                                        right: '8px',
                                    }"
                                >
                                    <v-icon
                                        background
                                        @click.stop="deleteFile(file.name)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-card>
                    </div>
                    <v-btn
                        @click="onButtonClick"
                        depressed
                        fab
                        x-small
                        color="primary"
                        class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <input
                        ref="uploader2"
                        class="d-none"
                        type="file"
                        accept="application/pdf, image/*"
                        @change="onFileChanged"
                    />
                </div>
            </v-col>
        </v-card-text>
        <v-card-actions ref="actions">
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                @click="generateReport"
                class="mb-2 mr-2"
                :loading="loading"
                :disabled="
                    report.date == undefined ||
                        project == undefined ||
                        report.projectLocation == undefined
                "
            >
                GENERATE REPORT
            </v-btn>
        </v-card-actions>
        <!-- Entry form -->
        <v-dialog persistent max-width="470px" v-model="entryDialog">
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeEntryDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ createEntryForm ? 'New Entry' : 'Edit Entry' }}
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider class="mx-5" />
                <v-card-text>
                    <!-- EQUIPMENT LOG -->
                    <v-form
                        v-model="valid"
                        class="mx-5 mt-3"
                        v-if="entryType == 'equipment'"
                    >
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.description"
                                    type="text"
                                    label="Description"
                                    prepend-icon="mdi-text"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.hoursUsed"
                                    type="number"
                                    label="Hours Used"
                                    prepend-icon="mdi-clock"
                                    :rules="[rules.number]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.timeDelivered"
                                    type="number"
                                    label="Time Delivered"
                                    prepend-icon="mdi-send-clock"
                                    :rules="[rules.number]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.timeRemoved"
                                    type="number"
                                    label="Time Removed"
                                    prepend-icon="mdi-clock-remove"
                                    :rules="[rules.number]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-textarea
                                    v-model="selectedEntry.notes"
                                    type="text"
                                    label="Notes"
                                    prepend-icon="mdi-text-box"
                                    rows="1"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- MATERIAL DELIVERY -->
                    <v-form
                        v-model="valid"
                        class="mx-5 mt-3"
                        v-if="entryType == 'material'"
                    >
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.description"
                                    type="text"
                                    label="Description"
                                    prepend-icon="mdi-text"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.qty"
                                    type="number"
                                    label="Quantity"
                                    prepend-icon="mdi-database"
                                    :rules="[rules.number]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.unit"
                                    label="Unit"
                                    prepend-icon="mdi-format-list-bulleted-type"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.unitCost"
                                    type="number"
                                    label="Unit Cost"
                                    prepend-icon="mdi-currency-usd"
                                    :rules="[rules.number]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-textarea
                                    v-model="selectedEntry.notes"
                                    type="text"
                                    label="Notes"
                                    prepend-icon="mdi-text-box"
                                    rows="1"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- ACTIVITY LOG -->
                    <v-form
                        v-model="valid"
                        class="mx-5 mt-3"
                        v-if="entryType == 'activity'"
                    >
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.activity"
                                    type="text"
                                    label="Activity *"
                                    prepend-icon="mdi-text"
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    v-model="selectedEntry.percentage"
                                    type="number"
                                    label="Percent Complete *"
                                    prepend-icon="mdi-percent"
                                    :rules="[rules.required, rules.number]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="py-0">
                                <v-textarea
                                    v-model="selectedEntry.notes"
                                    type="text"
                                    label="Notes"
                                    prepend-icon="mdi-text-box"
                                    required
                                    rows="1"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        :loading="loading"
                        :disabled="
                            !(
                                entryType == 'material' ||
                                entryType == 'equipment'
                            ) && !valid
                        "
                        @click="saveEntry"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import _ from 'lodash'
import axios from 'axios'
import { storage } from '@/services/firebase'
import { loadPDFPreview } from '@/components/Timesheets/dailyReport.js'

export default {
    name: 'DailyReportForm',
    props: {
        project: {
            type: Object,
            Required: true,
            default: () => ({}),
        },
        externalUsers: {
            type: Array,
            Required: true,
            default: () => [],
        },
        projectLocations: {
            type: Array,
            Required: true,
            default: () => [],
        },
        createForm: {
            type: Boolean,
            Required: true,
            default: () => true,
        },
        selectedReport: {
            type: Object,
            Required: true,
            default: () => ({
                equipmentEntries: [],
                materialEntries: [],
                activityEntries: [],
            }),
        },
        type: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        materialDeliveryOptions: {
            description: true,
            quantity: true,
            unit: true,
            unitCost: true,
            totalCost: true,
            notes: true,
        },
        loading: false,
        workLogs: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        menu: false,
        dataOptions: {
            weather: true,
            generalInfo: true,
            workLog: true,
            equipmentLog: true,
            materialDelivery: true,
            activityLog: true,
            perUser: true,
            userName: true,
            id: true,
            role: true,
            location: true,
            hours: true,
            notes: true,
            trade: true,
            accumulatedNotes: true,
            accumulatedHours: true,
            usersQty: true,
        },
        weatherData: null,
        report: {},
        height: 0,
        localAttachments: [],
        selectedFile: null,
        valid: false,
        entryType: '',
        entryDialog: false,
        selectedEntry: {},
        rules: {
            required: v => !!v || 'Required',
            number: v => v > 0 || 'Not valid',
            email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
            maxFiles: v => v.length < 4 || 'Max. 3 files',
        },
        createEntryForm: false,
        entryIndex: -1,
        equipmentHeaders: [
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'HOURS USED',
                value: 'hoursUsed',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TIME DELIVERED',
                value: 'timeDelivered',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TIME REMOVED',
                value: 'timeRemoved',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        materialHeaders: [
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUANTITY',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT',
                value: 'unit',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT COST',
                value: 'unitCost',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL COST',
                value: 'totalCost',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        activityHeaders: [
            {
                text: 'ACTIVITY',
                value: 'activity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PERCENT COMPLETE',
                value: 'percentage',
                align: 'center',
                sortable: false,
            },

            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
    }),
    computed: {
        filterMaterialHeaders() {
            let headers = []
            if (this.materialDeliveryOptions.description)
                headers.push({
                    text: 'DESCRIPTION',
                    value: 'description',
                    align: 'center',
                    sortable: false,
                })

            if (this.materialDeliveryOptions.quantity)
                headers.push({
                    text: 'QUANTITY',
                    value: 'qty',
                    align: 'center',
                    sortable: false,
                })

            if (this.materialDeliveryOptions.unit)
                headers.push({
                    text: 'UNIT',
                    value: 'unit',
                    align: 'center',
                    sortable: false,
                })

            if (this.materialDeliveryOptions.unitCost)
                headers.push({
                    text: 'UNIT COST',
                    value: 'unitCost',
                    align: 'center',
                    sortable: false,
                })

            if (this.materialDeliveryOptions.totalCost)
                headers.push({
                    text: 'TOTAL COST',
                    value: 'totalCost',
                    align: 'center',
                    sortable: false,
                })

            if (this.materialDeliveryOptions.notes)
                headers.push({
                    text: 'NOTES',
                    value: 'notes',
                    align: 'center',
                    sortable: false,
                })

            return headers
        },
        workLogHeaders() {
            let headers = []
            if (this.dataOptions.perUser) {
                if (this.dataOptions.id)
                    headers.push({
                        text: 'ID',
                        value: 'id',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.userName)
                    headers.push({
                        text: 'USER',
                        value: 'name',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.role)
                    headers.push({
                        text: 'ROLE',
                        value: 'role',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.hours)
                    headers.push({
                        text: 'HOURS',
                        value: 'time',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.notes)
                    headers.push({
                        text: 'NOTES',
                        value: 'notes',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.location)
                    headers.push({
                        text: 'LOCATION',
                        value: 'locationName',
                        align: 'center',
                        sortable: false,
                    })
            } else {
                if (this.dataOptions.trade)
                    headers.push({
                        text: 'TRADE',
                        value: 'trade',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.usersQty)
                    headers.push({
                        text: 'WORKERS QTY',
                        value: 'qty',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.accumulatedHours)
                    headers.push({
                        text: 'HOURS',
                        value: 'time',
                        align: 'center',
                        sortable: false,
                    })

                if (this.dataOptions.accumulatedNotes)
                    headers.push({
                        text: 'NOTES',
                        value: 'notes',
                        align: 'center',
                        sortable: false,
                    })
            }

            return headers
        },
        userReports() {
            let reports = []
            if (this.report && this.report.date) {
                this.externalUsers.forEach(user => {
                    if (user.timesheet) {
                        if (user.timesheet[this.report.date]) {
                            const entriesArray =
                                user.timesheet[this.report.date]
                            entriesArray.forEach(entry => {
                                if (entry.clockIn && entry.clockOut) {
                                    const data = {
                                        name: user.name,
                                        role: user.role,
                                        time:
                                            (entry.clockOut._seconds -
                                                entry.clockIn._seconds) /
                                            3600,
                                        locationName:
                                            entry.projectLocation.name,
                                        ...entry,
                                    }
                                    reports.push(data)
                                }
                            })
                        }
                    }
                })

                if (!this.dataOptions.perUser) {
                    const accumulated = {
                        qty: [...new Set(reports.map(r => r.name))].length,
                        time: reports.reduce(
                            (accum, report) => accum + Number(report.time),
                            0
                        ),
                    }
                    reports = [accumulated]
                }
            }
            return reports
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            this.report = this.selectedReport
            await this.getDocuments()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getDocuments() {
            try {
                this.loading = true
                if (this.report.number) {
                    let attachments = []
                    for (const doc of this.report.attachments) {
                        const storageRef = storage().ref(
                            `${this.companyId}/projects/${this.project.id}/timesheets`
                        )
                        const documentRef = storageRef.child(doc)
                        await documentRef
                            .getDownloadURL()
                            .then(response => {
                                attachments.push({
                                    name: doc,
                                    url: response,
                                })
                            })
                            .catch(() => {
                                attachments.push({
                                    name: doc,
                                    url: '',
                                })
                            })
                    }
                    this.$set(this.report, 'attachments', attachments)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async generateReport() {
            try {
                this.loading = true
                const location = this.report.projectLocation.location
                this.report.weather = await this.getWeatherData({
                    latitude: location._latitude,
                    longitude: location._longitude,
                    date: this.report.date,
                })
                let date = this.report.date
                if (this.report.date.length == 2) {
                    date = this.report.date[0] + ',' + this.report.date[1]
                }
                this.report.projectId = this.project.id
                this.report = {
                    dataOptions: this.dataOptions,
                    workLog: this.userReports,
                    ...this.report,
                }
                for (const file of this.localAttachments) {
                    await saveFile(
                        file,
                        `${this.companyId}/projects/${this.project.id}/timesheets`
                    )
                }
                const newReport = await API.createReport({
                    ...this.report,
                    ...(this.report.attachments
                        ? {
                              attachments: this.report.attachments.map(
                                  item => item.name
                              ),
                          }
                        : {}),
                    type: this.type,
                    date,
                })
                this.$emit('addReport', newReport)
                this.closeReportDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getWeatherData({ latitude, longitude, date }) {
            try {
                const url = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${latitude}%2C%20${longitude}/${
                    date.length == 2 ? `${date[0]}/${date[1]}` : date
                }`
                const params = {
                    unitGroup: 'metric',
                    key: process.env.VUE_APP_VISUAL_CROSSING_KEY,
                    contentType: 'json',
                }
                const response = await axios.get(url, { params })
                return response.data
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeReportDialog() {
            this.$emit('closeDialog')
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
                actions: { clientHeight: actionsHeight },
            } = this.$refs
            const windowHeight = window.innerHeight
            this.height = windowHeight - titleHeight - actionsHeight - 17
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                if (!this.report.number) {
                    const localFile = this.localAttachments.find(
                        f => f.name == file
                    )
                    if (localFile) {
                        const url = URL.createObjectURL(localFile)
                        window.open(url, '_blank')
                    }
                    return null
                }
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/timesheets`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteFile(file) {
            try {
                this.loading = true
                if (!this.report.number) {
                    this.localAttachments = this.localAttachments.filter(
                        f => f.name != file
                    )
                    this.report.attachments = this.report.attachments.filter(
                        f => f.name != file
                    )
                    return null
                }
                await deleteFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/timesheets`
                )
                this.report.attachments = this.reporte.attachments.filter(
                    f => f.name != file
                )
                await API.dailyReportAttachments({
                    id: this.report.id,
                    projectId: this.project.id,
                    files: this.report.attachments.map(f => f.name),
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile(file) {
            try {
                this.loading = true
                if (!this.report.number) {
                    this.localAttachments.push(file)
                    if (file.name.includes('.pdf')) {
                        file.pdfPreview = await loadPDFPreview(
                            URL.createObjectURL(file)
                        )
                    }
                    if (!this.report.attachments) {
                        this.report.attachments = [
                            {
                                name: file.name,
                                url: URL.createObjectURL(file),
                                ...(file.pdfPreview
                                    ? { pdfPreview: file.pdfPreview }
                                    : {}),
                            },
                        ]
                    } else {
                        this.report.attachments.push({
                            name: file.name,
                            url: URL.createObjectURL(file),
                            ...(file.pdfPreview
                                ? { pdfPreview: file.pdfPreview }
                                : {}),
                        })
                    }
                    return null
                }
                await saveFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/timesheets`
                )
                if (!this.report.attachments) {
                    this.report.attachments = [
                        { name: file.name, url: URL.createObjectURL(file) },
                    ]
                } else {
                    this.report.attachments.push({
                        name: file.name,
                        url: URL.createObjectURL(file),
                    })
                }
                await API.dailyReportAttachments({
                    id: this.packingList.id,
                    projectId: this.project.id,
                    files: this.report.attachments.map(item => item.name),
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onButtonClick() {
            this.$refs.uploader2.click()
        },
        async onFileChanged(e) {
            this.selectedFile = e.target.files[0]
            await this.saveFile(this.selectedFile)
        },
        setWLPerUser(value) {
            this.dataOptions.perUser = value
        },
        openEquipmentEntry() {
            this.entryType = 'equipment'
            this.selectedEntry = {}
            this.createEntryForm = true
            this.entryDialog = true
        },
        openMaterialEntry() {
            this.entryType = 'material'
            this.selectedEntry = {}
            this.createEntryForm = true
            this.entryDialog = true
        },
        openActivityEntry() {
            this.entryType = 'activity'
            this.selectedEntry = {}
            this.createEntryForm = true
            this.entryDialog = true
        },
        editEquipmentEntry(entry, index) {
            this.entryType = 'equipment'
            this.selectedEntry = _.cloneDeep(entry)
            this.entryIndex = index
            this.createEntryForm = false
            this.entryDialog = true
        },
        editMaterialEntry(entry, index) {
            this.entryType = 'material'
            this.selectedEntry = _.cloneDeep(entry)
            this.entryIndex = index
            this.createEntryForm = false
            this.entryDialog = true
        },
        editActivityEntry(entry, index) {
            this.entryType = 'activity'
            this.selectedEntry = _.cloneDeep(entry)
            this.entryIndex = index
            this.createEntryForm = false
            this.entryDialog = true
        },
        closeEntryDialog() {
            this.entryDialog = false
            this.entryType = ''
            this.selectedEntry = {}
            this.createEntryForm = false
        },
        saveEntry() {
            if (this.createEntryForm) {
                this.addEntry()
            } else {
                this.replaceEntry()
            }
        },
        addEntry() {
            this.report.equipmentEntries = this.report.equipmentEntries || []
            this.report.materialEntries = this.report.materialEntries || []
            this.report.activityEntries = this.report.activityEntries || []
            //equipment
            if (this.entryType == 'equipment')
                this.report.equipmentEntries.push(this.selectedEntry)

            //material
            if (this.entryType == 'material')
                this.report.materialEntries.push(this.selectedEntry)

            //activity
            if (this.entryType == 'activity')
                this.report.activityEntries.push(this.selectedEntry)

            this.report = _.cloneDeep(this.report)

            this.closeEntryDialog()
        },
        replaceEntry() {
            //equipment
            if (this.entryType == 'equipment')
                this.report.equipmentEntries.splice(
                    this.entryIndex,
                    1,
                    this.selectedEntry
                )

            //material
            if (this.entryType == 'material')
                this.report.materialEntries.splice(
                    this.entryIndex,
                    1,
                    this.selectedEntry
                )

            //activity
            if (this.entryType == 'activity')
                this.report.activityEntries.splice(
                    this.entryIndex,
                    1,
                    this.selectedEntry
                )

            this.report = _.cloneDeep(this.report)
            this.closeEntryDialog()
        },
        removeEntry(index, type) {
            //equipment
            if (type == 'equipment')
                this.report.equipmentEntries.splice(index, 1)

            //material
            if (type == 'material') this.report.materialEntries.splice(index, 1)

            //activity
            if (type == 'activity') this.report.activityEntries.splice(index, 1)

            this.report = _.cloneDeep(this.report)
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: this.project.currency,
                maximumFractionDigits: 0,
            }).format(Number(value))
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.card_pdf {
    max-width: 150px;
}
.preview_pdf {
    display: inline-block;
    max-height: 150px;
    max-width: 150px;
}
</style>
